<template>
  <KApp>
    <KMain>
      <KContainer class="fill-height pa-0 pa-md-4">
        <slot />
        <LayoutCommonSnackBar />
      </KContainer>
    </KMain>
  </KApp>
</template>

<script setup lang="ts">
// ログイン画面などで使うレイアウト。
// ヘッダーなしでコンテンツをセンタリングする。

const route = useRoute()

useHead({
  link: [
    {
      rel: 'canonical',
      href: getCanonicalBaseUrl() + route.path,
    },
  ],
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
})
</script>
